import { useFormik } from "formik"
import { FC } from "react"
import { DocumentNode, useMutation } from "@apollo/client";
import _ from "lodash";
import { wrapClick } from "utils";
import { Modal, TextArea } from "components";
import toast from "react-hot-toast";
import { ResetServiceOrderInitialValues, ResetServiceOrderSchema } from "./schema";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export { ResetServiceOrderSchema, ResetServiceOrderInitialValues } from './schema'

const validServiceOrderTypes = [
  'BacklogInstallationServiceOrder',
  'InstallationServiceOrder',
  'RegularizationInstallationServiceOrder',
  'ReplacementServiceOrder',
] as const;
type ValidServiceOrderType = typeof validServiceOrderTypes[number];


interface ResetServiceOrderFormProps {
  serviceOrderType: ValidServiceOrderType
  RESET_SERVICE_ORDER_QUERY: DocumentNode,
  open: boolean
  setOpen: (val: boolean) => void
  refetch: () => void;
}

export const ResetServiceOrderForm: FC<ResetServiceOrderFormProps> = ({
  serviceOrderType,
  RESET_SERVICE_ORDER_QUERY,
  open,
  setOpen,
  refetch,
}) => {

  const searchParams = useSearch<LocationGenerics>();

  const resetServiceOrderForm = useFormik({
    initialValues: ResetServiceOrderInitialValues,
    validationSchema: ResetServiceOrderSchema,
    onSubmit: (values) => {
      resetServiceOrder({
        variables: {
          id: searchParams.id,
          ...values,
        },
        fetchPolicy: "no-cache",
      }).then(({ data }) => {
        if(data?.resetServiceOrder?._id){
          toast(
            JSON.stringify({
              type: "success",
              title: "Service Order reset successfully",
            })
          );
          refetch()
          setOpen(false)
        } else {
          toast(
            JSON.stringify({
              type: "error",
              title: "Could not reset Service Order",
            })
          );
        }
      });
    },
  });

  const [resetServiceOrder, { loading }] = useMutation(
    RESET_SERVICE_ORDER_QUERY,
    {
      fetchPolicy: "no-cache",
    }
  );

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={`Reset ${serviceOrderType ? _.startCase(serviceOrderType) : "Service Order"}`}
      description='Indicate the reason for resetting the service order below'
      renderActions={() => (
          <>
            <button
              type='button'
              disabled={loading}
              className='w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm'
              onClick={wrapClick(resetServiceOrderForm.handleSubmit)}
            >
              {loading
                ? "Resetting Service Order..."
                : "Reset Service Order"}
            </button>
          </>
      )}
    >
      <div className="space-y-6 divide-y divide-gray-200">
        <div>
          <div className="grid gap-6 mt-2">
            <div>
              <TextArea
                id="reason"
                label="Reason"
                placeholder="e.g.  Reason for resetting service order"
                required={true}
                rows={10}
                {...resetServiceOrderForm}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ResetServiceOrderForm