import { ReactElement } from "react";
import { ReactLocation, MakeGenerics } from "react-location";
import { jsurl } from "utils";

export type LocationGenerics = MakeGenerics<{
  Search: {
    email?: string;
    token?: string;
    redirect?: string;
    fromDate?: string;
    toDate?: string;
    page?: number;
    pageSize?: number;
    search?: string;
    sort?: string;
    modal?:
    | "create"
    | "update"
    | "view"
    | "configure"
    | "calculate"
    | "assign"
    | "schedule"
    | "send"
    | "resolve"
    | "approve"
    | "reject"
    | "reassign"
    | "export"
    | "import"
    | "investigate"
    | "disable"
    | "enable"
    | "delete"
    | "view-work-order"
    |"reset"
    |"download"
    | "disapprove"
    | "reset-order";
    id?: string;
    region?: string;
    district?: string;
    block?: string;
    code?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    property?: string;
    role?: "SuperAdmin" | "Admin" | "Supervisor" | "Reader";
    month?: number;
    year?: number;
    tariffClass?: string;
    customerType?: string;
    serviceType?: string;
    serviceClass?: string;
    view?: "day" | "week" | "month" | "quarter" | "year" | "custom";
    orderStatus?: string;
    orderType?: string;
    priority?: string;
    category?: string;
    brand?: string;
    model?: string;
    location?: string;
    workOrderStatus?: string;
    prepaidSyncStatus?: string;
    parentContractor?: string;
    contractor?: string;
    contractorUser?: string;
  };
  Params: {
    region?: string;
    district?: string;
    block?: string;
    round?: string;
    plot?: string;
    itinerary?: string;
    tariffClass?: string;
    readingSet?: string;
    serviceRequest?: string;
    contractor?: string;
    parentContractor?: string;
    retirementSet?: string;
  };
  RouteMeta: {
    layout?: "App" | "Auth";
    breadcrumb: (params: LocationGenerics["Params"]) => ReactElement;
    section?: "General" | "Structure" | "Configuration" | "Users & Profiles";
  };
}>;

const location = new ReactLocation({
  parseSearch: jsurl.parseSearch,
  stringifySearch: jsurl.stringifySearch,
});

export default location;
