import { useQuery, useReactiveVar } from "@apollo/client";
import {
  ArrowUpTrayIcon,
} from "@heroicons/react/24/outline";
import { currentDistrictVar } from "apollo/cache/auth";
import { currentConfigVar } from "apollo/cache/config";
import {
  CalendarHeader,
  HeaderTabs,
  Shimmers,
  TableComponent,
  WorkOrderShipmentTable,
} from "components";
import ActionButton, { Action } from "components/buttons/action-button";
import { Avatar, SearchSelectInput, StatusBadge } from "components/core";
import config from "config";
import { ContractorPicker, ContractorUserPicker } from "containers";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData, useUrlState, wrapClick } from "utils";
import ApproveReplacementServiceOrderContainer from "./approve";
import AssignReplacementServiceOrderContainer from "./assign";
import AssignReplacementServiceOrderBulkContainer from "./assign-work-order";
import DisapproveReplacementServiceOrderContainer from "./disapprove";
import ExportDataContainer from "./export";
import {
  GET_SERVICE_ORDERS,
  GET_SERVICE_ORDER_SUMMARY,
  GET_SERVICE_WORK_ORDERS,
  RESET_SERVICE_ORDER
} from "./queries";
import ViewReplacementServiceOrderContainer from "./view";
import ViewReplacementServiceOrderAssignmentContainer from "./view-work-order";
import { ResetServiceOrderForm } from "components/forms";

const mainTabs = [
  { name: "Pending", href: "AssignedToSupplier", sort: "-assignedAt" },
  {
    name: "Assigned",
    href: "AssignedToInstaller",
    sort: "-contractorAssignedAt",
  },
  { name: "In Progress", href: "InProgress", sort: "-startedAt" },
  { name: "Resolved", href: "Resolved", sort: "-resolvedAt" },
  { name: "Disapproved", href: "Disapproved", sort: "-disapprovedAt" },
  { name: "Completed", href: "Completed", sort: "-completedAt" },
];

const altTabs = [{ name: "Work Order Shipments", href: "WorkOrderShipment" }];

const ReplacementServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
    () => {
      navigate({
        search: (old) => ({
          ...old,
          id,
          modal: action,
        }),
      });
    };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const [priority, setPriority] = useUrlState("priority");
  const [contractor, setContractor] = useUrlState("contractor");
  const [contractorUser, setContractorUser] = useUrlState("contractorUser");
  const [prepaidSyncStatus, setPrepaidSyncStatus] =
    useUrlState("prepaidSyncStatus");
  const [orderStatus] = useUrlState("orderStatus");
  const currentDistrict = useReactiveVar(currentDistrictVar);

  const filter = useMemo(() => {
    return {
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "installationMeterCode",
        "installedMeterCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
        "servicePointCode",
        "serviceRequestCode",
      ],
      ...(currentDistrict ? { district: currentDistrict } : {}),
      ...(searchParams?.orderStatus !== "WorkOrderShipment"
        ? {
            status: searchParams.orderStatus || undefined,
            installationMeterSystemSyncStatus:
              searchParams.prepaidSyncStatus || undefined,
            sort: mainTabs?.find(
              (item) => item.href === searchParams.orderStatus
            )?.sort,
          }
        : {}),
      priority: searchParams.priority || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
      farmingOutContractor: searchParams.contractor || undefined,
      farmingOutContractorUser: searchParams.contractorUser || undefined,
      dateField: "assignedAt",
    };
  }, [searchParams, currentDistrict]);

  const workOrdersFilter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: ["code"],
      sort: searchParams.sort || "",
      ...(currentDistrict ? { district: currentDistrict } : {}),
      status: searchParams?.workOrderStatus || undefined,
      fromDate: searchParams.fromDate || undefined,
      toDate: searchParams.toDate || undefined,
    }),
    [searchParams, currentDistrict]
  );

  const { data, loading, networkStatus, refetch } = useQuery(
    searchParams?.orderStatus === "WorkOrderShipment"
      ? GET_SERVICE_WORK_ORDERS
      : GET_SERVICE_ORDERS,
    {
      variables:
        searchParams?.orderStatus === "WorkOrderShipment"
          ? workOrdersFilter
          : filter,
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        refetchSummary?.();
      },
    }
  );

  const { data: dataSummary, refetch: refetchSummary } = useQuery(
    GET_SERVICE_ORDER_SUMMARY,
    {
      variables: filter,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "createdAt" } : {}),
        ...(!old?.orderStatus ? { orderStatus: "AssignedToSupplier" } : {}),
      }),
    });
  }, [navigate]);

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        id: undefined,
      }),
    });
  }, [searchParams.orderStatus, navigate]);

  useEffect(() => {
    setContractorUser(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor]);

  const records = useTableData(data || {});

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <CalendarHeader
        renderActions={() => (
          <>
            {currentDistrict && (
              <button
                type="button"
                onClick={wrapClick(() => setModal("upload"))}
                className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                <ArrowUpTrayIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                Upload Work Orders
              </button>
            )}
          </>
        )}
      />
      <HeaderTabs
        mainTabs={mainTabs}
        altTabs={altTabs}
        tabKey={"orderStatus"}
        summary={dataSummary?.summary}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          {searchParams?.orderStatus === "WorkOrderShipment" && (
            <WorkOrderShipmentTable
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              dispatchAction={dispatchAction}
            />
          )}

          {((searchParams?.orderStatus &&
            [
              "AssignedToSupplier",
              "AssignedToInstaller",
              "InProgress",
              "Resolved",
              "Completed",
              "Disapproved",
              "Rejected",
            ].includes(searchParams?.orderStatus)) ||
            !searchParams?.orderStatus) && (
            <TableComponent
              title={"regularization service orders"}
              refetch={refetch}
              isRefetching={loading && networkStatus === 4}
              loading={loading && ![4, 6].includes(networkStatus)}
              data={records}
              hasSearch={true}
              renderExport={({ exportOpen, setExportOpen }) => (
                <ExportDataContainer
                  entityType={""}
                  open={exportOpen}
                  setOpen={setExportOpen}
                  filter={filter}
                />
              )}
              renderHeaderItems={() => (
                <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                  {([
                    "AssignedToInstaller",
                    "Resolved",
                    "InProgress",
                    "Disapproved",
                    "Completed",
                  ].includes(searchParams?.orderStatus as string) ||
                    !searchParams?.orderStatus) && (
                      <>
                        <ContractorPicker
                          id="contractor"
                          labelHidden={true}
                          label={""}
                          placeholder="Select Contractor"
                          setFieldValue={(_: any, value: string) =>
                            setContractor(value)
                          }
                          filter={{ district: currentDistrict || undefined }}
                          values={{ contractor }}
                          rawId={true}
                        />
                        {contractor && (
                          <ContractorUserPicker
                            id="contractorUser"
                            labelHidden={true}
                            label={""}
                            placeholder="Select Agent"
                            setFieldValue={(_: any, value: string) =>
                              setContractorUser(value)
                            }
                            filter={{ contractor }}
                            values={{ contractorUser }}
                            rawId={true}
                          />
                        )}
                      </>
                  )}
                  <SearchSelectInput
                    id="priority"
                    labelHidden={true}
                    options={[
                      { label: { title: "Low" }, value: "Low" },
                      { label: { title: "Medium" }, value: "Medium" },
                      { label: { title: "High" }, value: "High" },
                      { label: { title: "Critical" }, value: "Critical" },
                    ]}
                    label={""}
                    placeholder="Select Priority"
                    setFieldValue={(_: any, value: string) =>
                      setPriority(value)
                    }
                    values={{ priority }}
                  />
                  {(["Completed"].includes(
                    searchParams?.orderStatus as string
                  ) ||
                    !searchParams?.orderStatus) && (
                    <SearchSelectInput
                      id="prepaidSyncStatus"
                      labelHidden={true}
                      options={[
                        { label: { title: "Pending" }, value: "Pending" },
                        { label: { title: "Attempted" }, value: "Attempted" },
                        {
                          label: { title: "Forward Synced" },
                          value: "ForwardSynced",
                        },
                        {
                          label: { title: "Reverse Synced" },
                          value: "ReverseSynced",
                        },
                        { label: { title: "Completed" }, value: "Completed" },
                      ]}
                      label={""}
                      placeholder="Select Sync Status"
                      setFieldValue={(_: any, value: string) =>
                        setPrepaidSyncStatus(value)
                      }
                      values={{ prepaidSyncStatus }}
                    />
                  )}
                </div>
              )}
              renderColumns={() => (
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Code | Date
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Customer
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Location
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    ServicePoint
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Tariff Class
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Old Meter
                  </th>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Disapproved",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Assigned Meter
                    </th>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Installed Meter
                    </th>
                  )}
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    Priority
                  </th>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Prepayment Sync Status
                    </th>
                  )}
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Disapproved",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Assigned | ETA
                    </th>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Resolution Period
                    </th>
                  )}
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Assigner
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Farming Out Contractor | Agent
                      </th>
                    </>
                  )}
                  {!orderStatus && (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Status
                    </th>
                  )}
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  ></th>
                </tr>
              )}
              renderLoader={() => (
                <tr>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.DoubleShimmer />
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.SingleShimmer />
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Disapproved",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.DoubleShimmer />
                    </td>
                  )}

                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <>
                      <td className="px-6 py-4 border-b border-gray-200">
                        <Shimmers.AvatarShimmer />
                      </td>
                      <td className="px-6 py-4 border-b border-gray-200">
                        <Shimmers.AvatarShimmer />
                      </td>
                    </>
                  )}
                  {!orderStatus && (
                    <td className="px-6 py-4 border-b border-gray-200">
                      <Shimmers.SingleShimmer />
                    </td>
                  )}
                  <td className="px-6 py-4 border-b border-gray-200">
                    <Shimmers.ActionsShimmer actionsCount={3} />
                  </td>
                </tr>
              )}
              renderItem={(item) => (
                <tr
                  key={item._id}
                  className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  onClick={wrapClick(dispatchAction(item._id, "view"))}
                >
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                    <div className="font-medium text-gray-900 dark:text-gray-100">
                      {item?.code || "N/A"}
                    </div>
                    <div className="font-medium text-gray-500 dark:text-gray-400">
                      {moment(item?.createdAt).format(dateFormat)}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.contactPerson?.fullName || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.contactPerson?.phoneNumber || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.location?.address || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.location?.community || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.servicePoint?.code || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.servicePoint?.geoCode || "N/A"}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.servicePoint?.tariffClass?.code || "N/A"} (
                      {item?.servicePoint?.tariffClass?.name || "N/A"})
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.meter?.modelMeta?.phase || "N/A"}{" "}
                    </div>
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <div className="text-gray-900 dark:text-gray-100">
                      {item?.meter?.code || "N/A"}
                    </div>
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.meter?.modelMeta?.brandName || "N/A"} -{" "}
                      {item?.meter?.modelMeta?.modelName || "N/A"}
                    </div>
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Disapproved",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.installationMeter?.code || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-500">
                        {item?.installationMeter?.modelMeta?.brandName || "N/A"}{" "}
                        -{" "}
                        {item?.installationMeter?.modelMeta?.modelName || "N/A"}
                      </div>
                    </td>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.installedMeter?.code || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-500">
                        {item?.installedMeter?.modelMeta?.brandName || "N/A"} -{" "}
                        {item?.installedMeter?.modelMeta?.modelName || "N/A"}
                      </div>
                    </td>
                  )}
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                    <StatusBadge
                      status={item?.priority}
                      options={{
                        High: "bg-orange-100 text-orange-800",
                        Critical: "bg-red-100 text-red-800",
                        Low: "bg-gray-100 text-gray-800",
                        Medium: "bg-yellow-100 text-yellow-800",
                      }}
                    />
                  </td>
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200">
                      <StatusBadge
                        status={item?.installationMeterSystemSyncStatus}
                        options={{
                          ForwardSynced: "bg-blue-100 text-blue-800",
                          ReverseSynced: "bg-orange-100 text-orange-800",
                          Completed: "bg-green-100 text-green-800",
                          Pending: "bg-yellow-100 text-yellow-800",
                          Attempted: "bg-red-100 text-red-800",
                        }}
                      />
                    </td>
                  )}
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Disapproved",
                    "Resolved",
                  ].includes(orderStatus) && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.estimatedResolutionDate
                          ? moment(item?.estimatedResolutionDate).format(
                              dateFormat
                            )
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {["Completed"].includes(orderStatus) && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                      <div className="text-gray-900 dark:text-gray-100">
                        <span className="text-xs mr-2 font-light">from</span>
                        {item?.assignedAt
                          ? moment(item?.assignedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        <span className="text-xs mr-2 font-light">to</span>
                        {item?.completedAt
                          ? moment(item?.completedAt).format(dateFormat)
                          : "N/A"}
                      </div>
                    </td>
                  )}
                  {[
                    "AssignedToInstaller",
                    "InProgress",
                    "Resolved",
                    "Disapproved",
                    "Completed",
                  ].includes(orderStatus) && (
                    <>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <Avatar
                              disabled={true}
                              alt={
                                [
                                  (
                                    item?.contractorAssigner?.lastName || ""
                                  )?.trim(),
                                  (
                                    item?.contractorAssigner?.firstName || ""
                                  )?.trim(),
                                ]
                                  .join(" ")
                                  .trim() || "N A"
                              }
                              src={
                                item?.contractorAssigner?.profileImageUrl || ""
                              }
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-gray-900 dark:text-gray-100">
                              {[
                                (
                                  item?.contractorAssigner?.lastName || ""
                                )?.trim(),
                                (
                                  item?.contractorAssigner?.firstName || ""
                                )?.trim(),
                              ]
                                .join(" ")
                                .trim() || "N A"}
                            </div>
                            <div className="text-gray-500 dark:text-gray-400">
                              {item?.contractorAssigner?.phoneNumber || "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                        <div className="flex items-center">
                          <div className="h-10 w-10 flex-shrink-0">
                            <Avatar
                              disabled={true}
                              alt={item?.farmingOutContractor?.name || "N A"}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-gray-900 dark:text-gray-100">
                              {item?.farmingOutContractor?.name || "N/A"}
                            </div>
                            <div className="text-gray-500 dark:text-gray-400">
                              {item?.farmingOutContractorUser?.username ||
                                [
                                  item?.farmingOutContractorUser?.firstName?.trim(),
                                  item?.farmingOutContractorUser?.lastName?.trim(),
                                ]
                                  .join(" ")
                                  ?.trim() ||
                                "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                    </>
                  )}
                  {!orderStatus && (
                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 ">
                      <StatusBadge
                        status={item?.status}
                        options={{
                          Pending: "bg-green-100 text-green-800",
                          Cancelled: "bg-red-100 text-red-800",
                        }}
                      />
                    </td>
                  )}
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 border-b border-gray-200 text-right">
                    <div className="space-x-1">
                      {!["Pending", "InProgress", "Rejected", "Resolved", "Disapproved", "Completed"].includes(
                        orderStatus,
                      ) && Boolean(orderStatus) && (
                        <ActionButton
                          action={'reset-order'}
                          onClick={dispatchAction(item?._id, "reset-order")}
                        />
                      )}
                      <ActionButton
                        action="view"
                        onClick={dispatchAction(item?._id, "view")}
                      />
                      {[
                        "AssignedToSupplier",
                        "AssignedToInstaller",
                        "InProgress",
                      ].includes(orderStatus) && (
                        <ActionButton
                          action={
                            item?.status === "AssignedToSupplier"
                              ? "assign"
                              : "reassign"
                          }
                          onClick={dispatchAction(item?._id, "assign")}
                        />
                      )}
                      {["Resolved"].includes(orderStatus) && (
                        <ActionButton
                          action="approve"
                          disabled={
                            item?.installationMeterSystemSyncMethod ===
                              "ReverseSync" && !item?.reverseSyncedAt
                          }
                          onClick={dispatchAction(item?._id, "approve")}
                        />
                      )}
                      {["Resolved"].includes(orderStatus) && (
                        <ActionButton
                          action="disapprove"
                          onClick={dispatchAction(item?._id, "disapprove")}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              )}
            />
          )}
        </div>
      </div>

      {currentDistrict && (
        <AssignReplacementServiceOrderBulkContainer
          open={modal === "upload"}
          setOpen={(val: boolean) => setModal(val ? "create-bulk" : undefined)}
          refetch={refetch}
        />
      )}

      {!!searchParams.id?.length && (
        <>
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <ResetServiceOrderForm
              open={modal === "reset-order"}
              setOpen={(val: boolean) => setModal(val ? "reset-order" : "")}
              serviceOrderType={"RegularizationInstallationServiceOrder"}
              RESET_SERVICE_ORDER_QUERY={RESET_SERVICE_ORDER}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <AssignReplacementServiceOrderContainer
              open={modal === "assign"}
              setOpen={(val: boolean) => setModal(val ? "assign" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <ApproveReplacementServiceOrderContainer
              open={modal === "approve"}
              setOpen={(val: boolean) => setModal(val ? "approve" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <DisapproveReplacementServiceOrderContainer
              open={modal === "disapprove"}
              setOpen={(val: boolean) => setModal(val ? "disapprove" : "view")}
              refetch={refetch}
            />
          )}
          {searchParams.orderStatus !== "WorkOrderShipment" && (
            <ViewReplacementServiceOrderContainer
              open={modal === "view"}
              setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
            />
          )}
          {searchParams.orderStatus === "WorkOrderShipment" && (
            <ViewReplacementServiceOrderAssignmentContainer
              open={modal === "view-work-order"}
              setOpen={(val: boolean) =>
                setModal(val ? "view-work-order" : undefined)
              }
            />
          )}
        </>
      )}
    </main>
  );
};

export default ReplacementServiceOrdersPage;
