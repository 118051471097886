import { gql } from "@apollo/client";

export const GET_SERVICE_ORDERS = gql`
  query GetRegularizationInstallationServiceOrders(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $priority: InstallationServiceOrderPriority
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    rows: getRegularizationInstallationServiceOrders(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      priority: $priority
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      farmingOutContractor {
        _id
        code
        name
      }
      farmingOutContractorUser {
        _id
        code
        username
        lastName
        firstName
      }
      contractorAssigner {
        _id
        lastName
        firstName
        phoneNumber
        emailAddress
        profileImageUrl
      }
      status
      priority
      notes
      estimatedResolutionDate
      servicePoint {
        _id
        code
        geoCode
        tariffClass {
          _id
          code
          name
        }
      }
      meter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
          phase
        }
      }
      installedMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
        system {
          _id
          code
          name
        }
        meterCode
      }
      installationMeterSystemCode
      installationMeterSystemSyncStatus
      installationMeterSystemSyncMethod
      assignedAt
      startedAt
      resolvedAt
      completedAt
      disapprovedAt
      reverseSyncedAt
      createdAt
      updatedAt
    }
    count: getRegularizationInstallationServiceOrdersCount(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      priority: $priority
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_ORDER_SUMMARY = gql`
  query GetRegularizationInstallationServiceOrderSummary(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $priority: InstallationServiceOrderPriority
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
  ) {
    summary: getRegularizationInstallationServiceOrderSummary(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      priority: $priority
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    ) {
      AssignedToSupplier
      AssignedToInstaller
      Resolved
      InProgress
      Completed
      Disapproved
    }
  }
`;

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $search: String
    $searchFields: [String!]
    $district: ID
    $status: InstallationServiceOrderStatus
    $priority: InstallationServiceOrderPriority
    $installationMeterSystemSyncStatus: InstallationMeterSystemSyncStatus
    $farmingOutContractor: ID
    $farmingOutContractorUser: ID
    $description: String
  ) {
    url: getRegularizationInstallationServiceOrderExportUrl(
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      search: $search
      searchFields: $searchFields
      district: $district
      status: $status
      priority: $priority
      description: $description
      installationMeterSystemSyncStatus: $installationMeterSystemSyncStatus
      farmingOutContractor: $farmingOutContractor
      farmingOutContractorUser: $farmingOutContractorUser
    )
  }
`;

export const GET_SERVICE_WORK_ORDERS = gql`
  query GetRegularizationInstallationServiceOrderAssignments(
    $fromDate: Date
    $toDate: Date
    $district: ID
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $status: InstallationServiceOrderAssignmentStatus
  ) {
    rows: getRegularizationInstallationServiceOrderAssignments(
      fromDate: $fromDate
      toDate: $toDate
      district: $district
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      status: $status
    ) {
      _id
      code
      status
      district {
        _id
        code
        name
      }
      meta {
        totalSuccessAssignments
        totalFailedAssignments
        totalAssignments
      }
      createdBy {
        _id
        lastName
        firstName
        phoneNumber
        profileImageUrl
      }
      createdAt
      updatedAt
    }
    count: getRegularizationInstallationServiceOrderAssignmentsCount(
      fromDate: $fromDate
      toDate: $toDate
      district: $district
      search: $search
      searchFields: $searchFields
      status: $status
    )
  }
`;


export const RESET_SERVICE_ORDER = gql`
    mutation ResetRegularizationInstallationServiceOrder(
        $id: ID!
        $reason: String!
    ) {
        resetServiceOrder: resetRegularizationInstallationServiceOrder(
            id: $id,
            reason: $reason
        ) {
            _id
        }
    }
`;